import React, { useState, useEffect } from "react";
import Routes from "./components/Routes/Routes";
//import { Authenticator } from 'aws-amplify-react';
import { Auth } from "aws-amplify";
import Navbar from './components/Navbar/Navbar'
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import './App.css';

//const AppContext = React.createContext({});

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  //const [accounts, setAccounts] = useState([]);

  /**const user = {
    isAuthenticated: { get: isAuthenticated, set: userHasAuthenticated },
    isAuthenticating: { get: isAuthenticating, set: setIsAuthenticating },
    username: { get: username, set: setUsername }
  };*/

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e)
      }
    }
  
    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating &&
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}> 
      <div className="App container">
        {/**<Authenticator hideDefault={true} onStateChange={handleAuthStateChange}>*/}
          <Navbar />
          <Routes accountId="464352597065" />
        {/*</Authenticator>*/}
      </div>
    </AppContext.Provider>
  );
}

export default App;
