import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import { onError } from "../../libs/errorLib";
import "../../App.css";

export default function Login() {
  const history = useHistory();

  const { userHasAuthenticated } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  //const [forgotPosition, setForgotPosition] = useState(35);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      if (e.name === 'UserNotFoundException' || e.name === 'NotAuthorizedException') {
        setError("Incorrect username or password")
      } else {
        console.log(e);
        onError(e);
        setIsLoading(false);
      }
    }
  }


  /**handleSigninSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const { email, pwd : password } = this.state;
    const lowerCaseEmail = email.toLowerCase()
    Auth.signIn(lowerCaseEmail, password)
      .then(user => {
        this.props.onStateChange("signedIn", user); //onStateChange
        // redirect to home page
      })
      .catch(e => {
        this.setState({ isLoading: false });
        if (e.name === 'UserNotFoundException' || e.name === 'NotAuthorizedException') {
          this.setState({ error: "Incorrect username or password" });
        } else {
          console.log(e);
          alert(e.message);
        }
      });
  };*/

  return (
      <div className="aepage login">
        <div className="loginForm ">
          <form onSubmit={handleSubmit}>
            <div className="emailPassword">
              <h3 className="miniTitle">
                Sign in to your existing account
              </h3>
              <div className="row">
                <label htmlFor="email" className="label">
                  Email address:
                </label>
                <input type="text"
                        id="email"
                        className="field"
                        placeholder="email@example.com"
                        value={fields.email}
                        onChange={handleFieldChange}
                        required />
              </div>
              <div className="row">
                <label htmlFor="password" className="label">
                  Password:
                </label>
                <input type="password"
                        id="pwd"
                        className="field"
                        value={fields.password}
                        onChange={handleFieldChange}
                        required />
              </div>
            </div>
            <div className="exceptions">
              {error}
            </div>
            <div>
              <button className="submit-bttn" disabled={!validateForm()} type="submit">Submit</button>
            </div>
          </form>
          <div className="forgotPwd">
            <a href="/forgotPassword">Forgot password?</a>
          </div>
        </div>
      </div>
  )
}