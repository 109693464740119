import React, { useState, useEffect } from 'react';
//import { Auth } from 'aws-amplify'
import ReactDataGrid from 'react-data-grid';
import "../../App.css";

const baseUrl = 'https://sh4zbbrqpj.execute-api.us-east-1.amazonaws.com/prod/'
//const accountId = "464352597065"
const planId = "75age1"

const sendGetRequest = async (urlPath) => {
    const apiUrl = `${baseUrl}${urlPath}`
    const results = await fetch(apiUrl, {
      method: 'GET',
      /*headers: {
        'x-api-key': apiKey
      }*/
    })
    return results.json()
  }

const Dashboard = props => {
    
  const [usage, setUsage] = useState([]);

  useEffect(() => {
    const getUsage = async (accountId) => {
      //const api_key_id = "7jaacfs6md"
      const path = "usage/account/" + accountId + "/usageplan/" + planId
      const jsonResults = await sendGetRequest(path)
      console.log(`getUsage jsonResults: ${jsonResults}`)
      setUsage(jsonResults)
    }
    getUsage(props.accountId)
  }, [props.accountId]);

  const renderUsage = () => {
    let rows = [{'KeyName': 'dummy key', 'YearMonth': '202001', 'Usage': 3}]
    const columns = [
        { key: 'KeyName', name: 'key_name' },
        { key: 'YearMonth', name: 'year_month' },
        { key: 'Usage', name: 'total_monthly_usage' }
    ];
    rows = usage.map((usage_item, key) =>
        ({ KeyName: usage_item.key_name, YearMonth: usage_item.year_month, Usage: usage_item.total_monthly_usage })
    );

    console.log(`usage data: ${rows}`)

    return(
        <div className="dashboardContainer formContainer">
            <h2 className="titleShort">Here is the usage data:</h2>
            <div>
                <ReactDataGrid
                    rows={rows}
                    columns={columns}
                    minHeight={150} />
            </div>
        </div>
    )
  }

  return (
    <div className="Dashboard">
        { renderUsage() }
    </div>
  )
}

export default Dashboard