import React from "react";
import NavbarSignedIn from "./NavbarSignedIn"
import { Link } from 'react-router-dom';
import { useAppContext } from "../../libs/contextLib";
import "./Navbar.css";

const Navbar = props => {
  const { isAuthenticated } = useAppContext();

  console.log(`isAuthenticated: `)
  console.log(isAuthenticated)

  return(<div className="navbar" id="navbar">
    <div className="navbarHeader">
      <div className="navbarBrand">
        <Link to="/" className="navbarBrandLink">
          <img className="navbarLogoImage" src={process.env.PUBLIC_URL + "/sw-logo-transpbackground.png"} alt="API Express logo" height="38" width="38" />
          <span className="navbarLogoText">API Express</span>
        </Link>
      </div>
    </div>
    {
      isAuthenticated ?
      (<NavbarSignedIn {...props}  />) :
      <div className="navbarNormal">
        <div className="navItem download">
          <Link to="/download" className="navbarItem">Download</Link>
        </div>
        <div className="navItem navLogin">
          <Link to="/login" className="navbarItem">Log in</Link>
        </div>
      </div>
    }
  </div>)
}

export default Navbar