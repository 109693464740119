export default {
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://sh4zbbrqpj.execute-api.us-east-1.amazonaws.com/prod" // TODO: change when DNS is applied!
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_GNDRYu4tz",
      APP_CLIENT_ID: "1vlg7k8hi3udlrb2o4949qarnc",
      IDENTITY_POOL_ID: "us-east-1:5e196510-ce65-459b-a8ce-ea21f3635924"
    }
  };

  //authenticationFlowType: 'USER_PASSWORD_AUTH'