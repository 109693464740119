import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Home";
import Dashboard from '../Dashboard/Dashboard';
import NotFound from '../NotFound/NotFound';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      <Route path="/dashboard" render={(props) =>
            <div className="swpage">
                <Dashboard {...this.props} />
            </div>} />
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}