import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../libs/contextLib";
// import AccountSwitcher from '../AccountSwitcher/AccountSwitcher'
import "./Navbar.css";

const NavbarSignedIn = props => {

  const history = useHistory();

  const { userHasAuthenticated } = useAppContext();

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }

  return(
    <div className="navbarNormal navbarNormalSignedIn">
      <div className="navItem about">
        <Link to="/about" className="navbarItem">About</Link>
      </div>
      <div className="navItem addAcccount">
        <Link to="/addAccount" className="navbarItem">+ New Acccount</Link>
      </div>
      <div className="navItem changePwd">
        <Link to="/changepwd" className="navbarItem">Change password</Link>
      </div>
      {/*<div className="navItem gameSwitcher">
        <AccountSwitcher {...props} />
      </div>*/}
      <div className="navItem logout">
        <Link to="/logout" onClick={handleLogout} className="navbarItem logout">Log out</Link>
      </div>
    </div>
  )
}

export default NavbarSignedIn
