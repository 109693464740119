import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import { onError } from "../../libs/errorLib";
import "./Signup.css";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined)

  function validateForm() {
    return (
      fields.firstname.length > 0 &&
      fields.lastname.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    console.log("Email: ")
    console.log(fields.email)

    const lowerCaseUsername = fields.email.toLowerCase()

    try {
      const newUser = await Auth.signUp({
        email: lowerCaseUsername,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      console.log(e)
      if(e.name === "UsernameExistsException") {
        // the use case we are dealiong with here is if the user signed up but then refreshed the confirmation page
        // if they sign up again we just want to send the confirmation code again to the same
        // email address
        setNewUser(lowerCaseUsername);
        Auth.resendSignUp(lowerCaseUsername)
      } else if(e.name === "InvalidPasswordException") {
        setError("Invalid password. Please select a password containing a combination of at least 8 lowercase and uppercase letters, numbers and symbols.")
      } else if(e.name === "InvalidParameterException") {
        setError(e.message)
      } else {
        setError(e)
      }
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      userHasAuthenticated(true);
      // TODO: show a confirmation succeeded page instead of going back to home page
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <div className="signup confirmationContainer">
        <div className="signupConfirmationForm">
          <form>
            <h3 className="miniTitle miniTitleLessPadded">
              Please check your inbox.
            </h3>
            <div className="explainer explainer-padded">
              We've sent you an email containing a confirmation code. Please enter it below.
            </div>
            <div className="row">
              <label className="label" htmlFor="confirmationCode">Confirmation Code</label>
              <input className="field" type="hidden" id="confCodeAutocomplete" />
              <input className="field" type="tel" id="confirmationCode" onChange={handleFieldChange} autoComplete="off" />
            </div>
            <div className="exceptions">
              {error}
            </div>
            <div className="conf-submit-bttn">
              <button type="submit" onClick={handleConfirmationSubmit} disabled={!validateConfirmationForm()}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="signup">
        <div className="signupForm">
          <form>
            <h3 className="miniTitle">
              New user? Create your account on API Express.
            </h3>
            <div className="row">
              <label htmlFor="firstname" className="label">
                First name:
              </label>
              <input id="firstname"
                      className="field"
                      type="text"
                      value={fields.firstname}
                      onChange={handleFieldChange}
                      autoFocus={true}
                      required />
            </div>
            <div className="row">
              <label htmlFor="lastname" className="label">
                Last name:
              </label>
              <input id="lastname"
                      className="field"
                      type="text"
                      value={fields.lastname}
                      onChange={handleFieldChange}
                      required />
            </div>
            <div className="row">
              <label htmlFor="company" className="label">
                Company (optional):
              </label>
              <input id="company"
                      className="field"
                      type="text"
                      onChange={handleFieldChange} />
            </div>
            <div className="row">
              <label htmlFor="email" className="label">
                Email address:
              </label>
              <input id="email"
                      name="email"
                      className="field"
                      placeholder="email@example.com"
                      value={fields.email}
                      type="text"
                      onChange={handleFieldChange} />
            </div>
            <div className="row">
              <label htmlFor="password" className="label">
                Password:
              </label>
              <input id="password"
                      className="field"
                      value={fields.password}
                      type="password"
                      onChange={handleFieldChange} />
            </div>
            <div className="row">
              <label htmlFor="confirmPassword" className="label">
                Confirm password:
              </label>
              <input id="confirmPassword"
                      className="field"
                      type="password"
                      value={fields.confirmPassword}
                      onChange={handleFieldChange} />
            </div>
            <div className="checkboxRow">
              <input type="checkbox" className="checkboxField" name="acceptTOS" id="acceptTOS" onChange={handleFieldChange} />
              <label htmlFor="acceptTOS" className="checkboxLabel">
                I have read and accept the <a href="/terms">Terms of service</a>.
              </label>
            </div>
            <div className="exceptions">
              {error}
            </div>
            <div className="submit-bttn">
              <button type="submit" onClick={handleSubmit} disabled={!validateForm()}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className="aepage Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}